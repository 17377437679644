<template>
  <div>
    <el-table
      :row-key="record => record.uuid"
      :data="logs"
      :loading="loading"
    >

      <el-table-column prop="content" label="日志内容"></el-table-column>
      <el-table-column  label="日志类型">
        <div slot-scope="scope">
          <el-tag v-if="scope.row.type === 1" type="warning" effect="plain">授权信息</el-tag>
          <el-tag v-if="scope.row.type === 2" effect="plain">设备活动</el-tag>
          <el-tag v-if="scope.row.type === 3" type="danger" effect="plain">信息变更</el-tag>
        </div>
      </el-table-column>

      <el-table-column label="时间">
        <div slot-scope="scope" >
          {{new Date(scope.row.createdAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}
        </div>
      </el-table-column>

    </el-table>
    <el-pagination
      @current-change="handleNext"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total"></el-pagination>
  </div>
</template>

<script>

  export default {
    name: 'equipment-detail-drink',
    components: {},
    props:{
      uuid: {
        type: String,
      }
    },
    mounted: function () {
      this.loadInfos(this.uuid)
    },
    data: function () {
      return {
        logs: [],
        loading: false,
        pagination: {
          uuid: '',
          type: 0,
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
        },
        columns: [{
          title: '日志内容',
          dataIndex: 'content',
          width: '36%'
        },
          {
            title: '日志类型',
            scopedSlots: {customRender: 'type'},
            width: '18%',
            key: 'type',
            filterMultiple: false,
            filters: [
              {text: '全部', value: '0'},
              {text: '授权信息', value: '1'},
              {text: '设备活动', value: '2'},
              {text: '信息变更', value: '3'},
            ],
          },
          {
            title: '时间',
            scopedSlots: {customRender: 'lastLoginAt'},
            width: '18%'
          },

        ],
      }
    },
    methods: {
      loadInfos(uuid, type=0) {
        this.pagination.uuid = uuid
        this.$api('equipment.device.logUnion',{uuid: uuid, type:type}).then(data => {
          this.logs = data.list || []
          this.pagination.total = parseInt(data.total)
        })
      },
      handleTableChange(pagination, filters){
        this.loadInfos(this.uuid, filters.type[0])

      },
      handleNext(page) {
        this.pagination.start = (page - 1) * this.pagination.limit
        this.$api('equipment.device.logs',this.pagination).then(data => {
          this.logs = data || []
        })
      },
    },

  }
</script>


<style lang="scss" scoped>
  .drink-info {
    display: flex;
    align-content: center;
    align-items: center;
    ::v-deep .el-table:after{
      display: none;
    }

    &-image {
      img {
        max-width: 64px;
      }
    }
  }
</style>
