<template>
  <div class="device-detail">
    <step-container :name="device.name">
      <template slot="back" class="device-detail-header-btn">
         <span
           class="device-info">最近上线时间:{{new Date(device.lastLoginAt * 1000).Format('yyyy-MM-dd hh:mm:ss')}}</span>
        <el-tag v-if="device.isLock === 1" size="small" type="warning">
          <i class="el-icon-lock"/>
          已锁定
        </el-tag>
        <el-tag v-if="device.connectStatus === 1" size="small" >
          <i class="el-icon-link"/>
          已离线
        </el-tag>
        <el-tag v-if="device.connectStatus === 2" size="small" effect="plain" type="success">
          <i class="el-icon-connection"/>
          运行中
        </el-tag>

        <el-tag v-if="device.runStatus === 0" effect="plain" type="warning" size="small">
          <i class="el-icon-circle-close"/>
          暂未激活
        </el-tag>
        <el-tag v-if="device.runStatus === 1" effect="plain" size="small">
          <i class="el-icon-circle-check"/>
          正常运行
        </el-tag>
        <el-tag v-if="device.runStatus === 2" effect="plain" size="small" type="danger">
          <i class="iconfont icon-remind"/>
          异常预警
        </el-tag>
      </template>
      <template slot="step">
        <template v-if="device.authType === 1">
          <el-button type="text"  @click="handleDevice" v-if="$btnCheck('equipment_device_update')">
            <i class="iconfont icon-bianji"/>
            信息编辑
          </el-button>

          <el-button type="text"  @click="handleAuth" v-if="$btnCheck('equipment_device_auth')">
            <i class="iconfont icon-audit"/>
            客户授权
          </el-button>

          <el-button type="text" >
            <i class="iconfont icon-file-sync"/>
            拉取饮品
          </el-button>

          <el-button type="text" >
            <i class="iconfont icon-transaction"/>
            价格同步
          </el-button>
        </template>
      </template>

    </step-container>

    <el-tabs v-model="step" class="device-detail-container">
      <el-tab-pane name="info" label="设备饮品">
        <device-drink ref="drink" :show-sync="device.connectStatus === 2"></device-drink>
      </el-tab-pane>
      <el-tab-pane name="order" label="制作订单" v-if="$btnCheck('order_query_info')">
        <device-order v-if="step==='order'"></device-order>
      </el-tab-pane>
      <el-tab-pane name="log" label="运行日志" v-if="device.authType === 1">
        <device-log :uuid="device.uuid"></device-log>
      </el-tab-pane>
      <el-tab-pane name="auth" label="授权信息" v-if="device.authType === 1">
        <device-auth :uuid="device.uuid" ref="auth"></device-auth>
      </el-tab-pane>
    </el-tabs>


    <el-dialog class="dialog"

               :visible.sync="updateDeviceVisible"
               width="736px"
               style="padding-right:24px"
               title="更新设备位置">
      <el-form label-width="80px">
        <el-form-item label="设备名称">
          <el-input
            v-model="changeDevice.name"
          />
        </el-form-item>
        <el-form-item label="设备锁定">
          <el-switch v-model="lockStatus" @change="handleLock"
                     checked-children="解锁" un-checked-children="锁定"></el-switch>
        </el-form-item>

        <el-form-item label="设备位置">
          <map-container ref="map"></map-container>
        </el-form-item>
      </el-form>

      <template slot="footer">
        <el-button type="primary" @click="handleUpdate">确定</el-button>
        <el-button @click="updateDeviceVisible=false">取消</el-button>
      </template>
    </el-dialog>

    <el-dialog :visible.sync="authDeviceVisible"
               width="560px"
               style="padding-right:24px"
               title="授权客户">
      <el-form label-width="80px">
        <el-form-item label="客户名称">
          <InfiniteScrollSelect
            v-model="customer.orgName"
            placeholder="请输入客户名称"
            options-type="remote"
            valueKey="name"
            :fuzzyMatching="true"
            :pageable="true"
            :remoteOptionsApi="loadOrg"
            @change="handleChange"
          />
        </el-form-item>

      </el-form>

      <template slot="footer">
        <el-button type="primary" @click="handleAuthCommit">确定</el-button>
        <el-button @click="authDeviceVisible=false">取消</el-button>
      </template>

    </el-dialog>


  </div>

</template>

<script>

  import DeviceDrink from './detail_drink'
  import DeviceAuth from './detail_auth'
  import DeviceLog from './detail_log'
  import DeviceOrder from './detail_order'

  import MapContainer from '@/components/Map'
  import InfiniteScrollSelect from '@/components/InfiniteScrollSelect';
  import {mapGetters} from "vuex";

  export default {
    name: '',
    components: {
      DeviceDrink,
      DeviceAuth,
      DeviceLog,
      DeviceOrder,
      MapContainer,
      InfiniteScrollSelect
    },
    mounted: function () {
      this.$api('equipment.device.detail',{uuid: this.$route.params.uuid}).then(data => {
        this.device = data
        this.lockStatus = this.device.isLock === 1
        this.step = this.$route.params.step || 'info'
      })
    },
    data: function () {
      return {
        loadOrg: function (params) {
          return this.$api('account.org.relate', params)
        },
        updateDeviceVisible: false,
        authDeviceVisible: false,
        changeDevice: {},
        lockStatus: false,
        step: 'info',
        search: {},
        customer: {
          uuid: '',
          orgUuid: '',
          orgName: '',
          proveName: ''
        },
        device: {
          uuid: '',
          name: '',
          lastLoginAt: 0,
          connectStatus: 1,
          runStatus: 2,
        }
      }
    },
    computed: {
      ...mapGetters(['prove']),
    },
    methods: {
      handleLock(checked) {
        this.changeDevice.lockStatus = checked
      },
      handleAuth() {
        this.customer.uuid = this.$route.params.uuid
        this.customer.proveName = this.prove.name
        this.authDeviceVisible = true
      },
      handleChange(value) {
        this.customer.orgName = value.name
        this.customer.orgUuid = value.uuid
      },
      handleAuthCommit() {
        if (!this.customer.orgUuid) {
          this.$message.warning('请选取授权客户')
          return
        }
        this.$api('equipment.device.authorize',{},this.customer).then(() => {
          this.$message.success('授权成功')
          this.authDeviceVisible = false
          this.$refs.auth.loadInfos(this.$route.params.uuid)
          return
        })
      },
      handleUpdate() {
        if (!this.changeDevice.name) {
          this.$message.warning('请输入设备名称')
          return
        }
        let pos = this.$refs.map.getPos()
        if (!pos.province) {
          this.$message.warning('请选取设备地址')
          return
        }

        this.changeDevice.proveName = this.prove.name
        this.changeDevice.province = pos.province
        this.changeDevice.city = pos.city
        this.changeDevice.address = pos.address
        this.changeDevice.isLock = this.lockStatus ? 1 : 0
        this.changeDevice.lat = pos.lat + ''
        this.changeDevice.lng = pos.lng + ''
        this.$api('equipment.device.update',{},this.changeDevice).then(() => {
          this.device = this.changeDevice
          this.device.isLock = this.lockStatus ? 1 : 0
          this.updateDeviceVisible = false
          this.$message.success('更新成功')
        })


      },
      handleDevice() {
        // let vue = this
        this.updateDeviceVisible = true
        this.changeDevice = Object.assign({}, this.device)
        this.lockStatus = this.device.isLock === 1
        this.$nextTick(() => {
          this.$refs.map.initMap(this.changeDevice)
        })
      },
    },
  }
</script>

<style lang="scss" scoped>

  ::v-deep .el-dialog__body {
    padding-bottom: 0;
  }

  .device-detail {
    width: 100%;
    height: calc(100% - 36px);
    display: flex;
    flex-direction: column;
    .device-info {
      margin: 0 $middle-space;
      @include font-little-h();
    }

    &-header-btn {
      .el-button{
        color: $color-base;
        padding-left: 0;
      }
    }

    .tip-container {
      text-align: right;

      .tip {
        width: 124px;
      }
    }
    .el-tag {
      margin-right: $small-space;
    }


    &-container {
      margin: $middle-space;
      padding: 0 $container-padding;
      padding-top: $middle-space;
      background-color: $color-white;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      position: relative;
      height: 100%;

      ::v-deep {
        .el-tabs__header {
          position: absolute;
          top: $middle-space;
          left: 0;
          padding-left: $container-padding;
          padding-right: $container-padding;
          width: 100%;
        }

        .el-tabs__content {
          margin-top: 48px;
          overflow-y: scroll;
          flex: 1;
        }
      }

    }
  }
</style>
