<template>

  <div>
    <el-table
      :row-key="record => record.uuid"
      :data="drinks"
      :loading="loading"
    >
      <el-table-column label="饮品信息" width="240">
        <el-row slot="header"
                slot-scope="scope"
                type="flex" justify="center" style="align-items: center;align-content: center">
          <el-col :span="8"><p>饮品名称</p></el-col>
          <el-col :span="16">
            <el-input
              v-model="search"
              clearable
              @change="handleSearch(scope)"
              size="mini"
              placeholder="输入关键字搜索"/>
          </el-col>
        </el-row>
        <div slot-scope="scope" class="drink-info">
          <div class="drink-info-image">
            <img :src="scope.row.img">
          </div>
          <div class="drink-info-text">
            <p>饮品名称: {{scope.row.name}}</p>
            <p>英文名称: {{scope.row.engName}}</p>
          </div>
        </div>
      </el-table-column>

      <el-table-column label="小杯价格" align="center">
        <div slot-scope="scope" v-if="scope.row.specIdx.txt.indexOf('0') !== -1">
          <span>{{scope.row.specs[scope.row.specIdx.idx.get('0')].price / 100 | F1000}}</span>
        </div>
        <span v-else>-</span>
      </el-table-column>
      <el-table-column label="中杯价格" align="center">
        <div slot-scope="scope" v-if="scope.row.specIdx.txt.indexOf('1') !== -1">
          <span>{{scope.row.specs[scope.row.specIdx.idx.get('1')].price / 100 | F1000}}</span>
        </div>
        <span v-else>-</span>
      </el-table-column>
      <el-table-column label="大杯价格" align="center">
        <div slot-scope="scope" v-if="scope.row.specIdx.txt.indexOf('2') !== -1">
          <span>{{scope.row.specs[scope.row.specIdx.idx.get('2')].price / 100 | F1000}}</span>
        </div>
        <span v-else>-</span>
      </el-table-column>
      <el-table-column label="饮品排序(越大越靠前)" prop="orderCond" align="center"></el-table-column>

      <el-table-column
        v-if="$btnCheck('equipment_device_drinkUpdate') || $btnCheck('equipment_device_drinkAllUpdate')"
        label="更多操作">
        <div slot-scope="scope">
          <el-button type="text"
                     v-if="$btnCheck('equipment_device_drinkUpdate')"

                     @click="handleEditDrink(scope.row)"
                     size="small">价格调整
          </el-button>
          <el-button type="text"
                     v-if="$btnCheck('equipment_device_drinkAllUpdate')"
                     @click="$router.push({name: 'equipment.device.drink',
                     params: {deviceUUID:$route.params.uuid, uuid: scope.row.uuid}})"
                     size="small">参数编辑
          </el-button>
        </div>
      </el-table-column>

    </el-table>
    <el-dialog :visible.sync="editCoffeeVisible" class="cup-dialog" :title="'编辑饮品'" :width="'42rem'">
      <el-row :gutter="20">
        <el-col :span="11">
          <upload :url="drink.img"></upload>
        </el-col>
        <el-col :span="12">
          <el-form label-width="120px" size="mini">
            <el-form-item label="饮品名称">
              <el-input v-model="drink.name" placeholder="请输入饮品名称"/>
            </el-form-item>
            <el-form-item label="饮品英文名称">
              <el-input v-model="drink.engName" placeholder="请输入饮品名称"/>
            </el-form-item>
            <el-form-item label="小杯价格" v-if="drink.specIdx.txt.indexOf('0') !== -1">
              <el-input v-model="drink.specs[drink.specIdx.idx.get('0')].price" placeholder="单位为元，支持小数点后两位"
                        @change="drink.specs[drink.specIdx.idx.get('0')].price=materielExtraCostChange(drink.specs[drink.specIdx.idx.get('0')].price)"
                        :min="0"/>
            </el-form-item>

            <el-form-item label="中杯价格" v-if="drink.specIdx.txt.indexOf('1') !== -1">
              <el-input v-model="drink.specs[drink.specIdx.idx.get('1')].price" placeholder="单位为元，支持小数点后两位"
                        @change="drink.specs[drink.specIdx.idx.get('1')].price=materielExtraCostChange(drink.specs[drink.specIdx.idx.get('1')].price)"
                        :min="0"/>
            </el-form-item>
            <el-form-item label="大杯价格" v-if="drink.specIdx.txt.indexOf('2') !== -1">
              <el-input v-model="drink.specs[drink.specIdx.idx.get('2')].price" placeholder="单位为元，支持小数点后两位"
                        @change="drink.specs[drink.specIdx.idx.get('2')].price=materielExtraCostChange(drink.specs[drink.specIdx.idx.get('2')].price)"
                        :min="0"/>
            </el-form-item>
            <el-form-item label="排序位置">
              <el-input size="mini" v-model.number="drink.orderCond" placeholder="越小越靠前"></el-input>
            </el-form-item>


          </el-form>
        </el-col>
      </el-row>
      <div slot="footer" style="margin-right: 88px">
        <span style="margin-right: 8px " v-if="showSync"><el-checkbox v-model="sync"></el-checkbox> 立即同步设备</span>
        <el-button size="mini" type="primary" @click="handleCommit"> 保存</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
  import Upload from '@/components/Upload'

  export default {
    name: 'equipment-detail-drink',
    components: {
      Upload
    },
    mounted: function () {
      let uuid = this.$route.params.uuid
      if (uuid) {
        this.loadDrinks(uuid)
      }
    },
    props: {
      showSync: {
        type: Boolean,
        default: false
      }
    },
    data: function () {
      return {
        drinks: [],
        oldDrinks :[],
        loading: false,
        sync: true,
        search: '',
        editCoffeeVisible: false,
        drink: {
          drinkUuid: '',
          deviceUuid: '',
          name: '',
          img: '',
          specIdx: {
            idx: new Map(),
            txt: ''
          },
          specs: [
            {
              price: 0,
            },
            {
              price: 0,
            },
            {
              price: 0,
            }
          ],
          engName: '',
          orderCond: 0,
        }
      }
    },
    methods: {
      materielExtraCostChange(item) {
        // 防止删除为空
        if (!item) {
          item = '0.00'
        }
        // 一些错误金额输入的判断
        if (item.toString().indexOf('.') > 0 && Number(item.toString().split('.')[1].length) < 1) {
          item = item.toString().split('.')[0]
        }
        // 一些错误金额输入的判断
        if (!item || item === '-' || item === '-0') {
          item = '0.00'
        }
        return parseFloat(item).toFixed(2)

      },
      change(e) {
        this.search += e
        this.$forceUpdate()
      },
      handleSearch() {
        let v = this.search
        if (v) {
          let drinks = this.oldDrinks.filter(function(item) {
            return item.name.indexOf(v) > -1 || item.engName.indexOf(v) > -1
          });
          this.drinks = drinks
        } else {
          this.drinks = this.oldDrinks
        }
      },
      handleEditDrink(drink) {
        let specIdx = drink.specIdx
        this.drink = JSON.parse(JSON.stringify(drink))
        this.drink.specIdx = specIdx
        for (let i = 0; i < this.drink.specs.length; i++) {
          this.drink.specs[i].price = this.drink.specs[i].price / 100
        }
        this.editCoffeeVisible = true
      },
      loadDrinks(uuid) {
        this.$api('equipment.device.drinks',{uuid: uuid}).then(data => {
          let drinks = data || []
          for (let i = 0; i < drinks.length; i++) {
            drinks[i].specIdx = {
              txt: '',
              idx: new Map()
            }
            if (drinks[i].specs) {
              for (let j = 0; j < drinks[i].specs.length; j++) {
                let textFlag = drinks[i].specs[j].textFlag
                drinks[i].specIdx.txt += textFlag + `,`
                drinks[i].specIdx.idx.set(textFlag, j)
              }
            }

          }
          this.drinks = drinks
          this.oldDrinks = drinks
        })
      },
      handleCommit() {
        let drink = JSON.parse(JSON.stringify(this.drink))
        for (let i = 0; i < drink.specs.length; i++) {
          drink.specs[i].price = drink.specs[i].price * 100
        }
        if (!this.showSync) {
          this.sync = false
        }
        this.$api('equipment.device.drinkPriceUpdate',{},
          {...drink,drinkMd5: drink.drinkUuid, sync: this.sync, deviceUuid: this.$route.params.uuid}).then(() => {
          this.$message.success('更新成功')
          this.loadDrinks(this.$route.params.uuid)
          this.editCoffeeVisible = false
        })
      }
    },

  }
</script>


<style lang="scss" scoped>
  .drink-info {
    display: flex;
    align-content: center;
    align-items: center;

    &-image {
      img {
        max-width: 64px;
      }
    }
  }
</style>
