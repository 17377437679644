<template>
  <el-table
    :row-key="record => record.uuid"
    :data="auths"
    :loading="loading"
  >


    <el-table-column label="客户信息">
      <div slot-scope="scope">
        <div v-if="scope.row.orgName">
          <p>客户名称: {{scope.row.orgName}}</p>
          <p>客户编号: {{scope.row.orgUuid}}</p>
        </div>
        <div v-else>企业自有</div>
      </div>
    </el-table-column>

    <el-table-column label="授权类别">
      <div slot-scope="scope">{{scope.row.authorizeType | filterAuthType}}</div>
    </el-table-column>

    <el-table-column label="授权人">

      <div slot-scope="scope">
        <div v-if="scope.row.proveName">
          <p>授权员工: {{scope.row.proveName}}</p>
          <p>员工编号: {{scope.row.proveUuid}}</p>
        </div>
        <div v-else>系统分配</div>
      </div>

    </el-table-column>

    <el-table-column label="操作" v-if="$btnCheck('equip_auth')">
      <div slot-scope="scope">
        <el-button type="text" @click="handleAuthRemove(scope.row)" :disabled="scope.row.orgName === ''">移除</el-button>
      </div>
    </el-table-column>


  </el-table>
</template>

<script>
  import {mapGetters} from "vuex";

  export default {
    name: 'equipment-detail-drink',
    components: {},
    props: {
      uuid: {
        type: String,
      }
    },
    computed: {
      ...mapGetters(['prove']),
    },
    mounted: function () {
      this.loadInfos(this.uuid)
    },
    filters: {
      filterAuthType(v) {
        switch (v) {
          case 1:
            return `自营`
          case 2:
            return `租赁`
          case 3:
            return `分享`
        }
      }
    },
    data: function () {
      return {
        auths: [],
        loading: false,
        columns: [{
          title: '客户信息',
          scopedSlots: {customRender: 'info'},
          width: '36%'
        },
          {
            title: '授权人',
            scopedSlots: {customRender: 'auth'},
            width: '18%'
          },
          {
            title: '操作',
            scopedSlots: {customRender: 'lastLoginAt'},
            width: '18%'
          },

        ],
        pagination: {
          start: 0,
          curPage: 1,
          limit: 10,
          total: 1,
        },
      }
    },
    methods: {
      loadInfos(uuid) {
        this.$api('equipment.device.authorizeList',{uuid: uuid}).then(data => {
          this.auths = data || []
        })
      },
      handleAuthRemove(auth) {
        let vue = this
        this.$confirm('取消授权客户将无法看到设备，是否继续？', '提示').then(() => {
          this.$api('equipment.device.authorizeCancel',{}, {
            uuid: vue.uuid,
            orgUuid: auth.orgUuid,
            orgName: auth.orgName,
            proveName: vue.prove.name,
          }).then(() => {
            let idx = vue.auths.findIndex((item) => {
              return item.orgUuid === auth.orgUuid
            })
            vue.auths.splice(idx, 1)
          })
        }).catch(() => {
        })
      },
    },

  }
</script>


<style lang="scss" scoped>
  .drink-info {
    display: flex;
    align-content: center;
    align-items: center;

    &-image {
      img {
        max-width: 64px;
      }
    }
  }
</style>
